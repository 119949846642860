import { useEffect, useState } from 'react';
import { Chat } from 'stream-chat-react';
import { StreamChat, Event } from 'stream-chat';
import { useAuth0 } from '@auth0/auth0-react';

import { SplashScreen, Messages } from '../../components/SplashScreen';
import { themeSelector, useChatServicesStore, useStreamTokenProvider } from '../../hooks';
import { ChildrenOnly, METADATA_URL, UserPayload } from '../../types';

const {
  REACT_APP_STREAM_API_KEY: streamApiKey,
  REACT_APP_CHAT_SERVER_ENDPOINT: chatServerEndpoint,
} = process.env;

export const ChatServicesWrapper = ({ children }: ChildrenOnly) => {
  const { user } = useAuth0<UserPayload>();

  const [theme] = useChatServicesStore(themeSelector);

  const streamTokenProvider = useStreamTokenProvider();

  const [message, setMessage] = useState<React.ReactNode | null>(Messages.LOADING);
  const [client, setClient] = useState<StreamChat | null>(null);

  useEffect(() => {
    if (!user) return;

    const chatAPIClient = new StreamChat(streamApiKey, {
      baseURL: chatServerEndpoint || undefined,
    });

    const timeout = setTimeout(() => {
      setMessage(Messages.ERROR);
    }, 10000);

    setMessage(Messages.CONNECTING);

    chatAPIClient
      .connectUser({ id: user[METADATA_URL].stream_user_id }, streamTokenProvider)
      .catch((error) => {
        setMessage(Messages.ERROR);
        console.error(error);
      })
      .then(() => {
        clearTimeout(timeout);
      });

    const handleConnectionChange = ({ online: isOnline = false }: Event) => {
      // setMessage(isOnline ? null : Messages.ERROR);
      if (isOnline) {
        setMessage(null);
        setClient(chatAPIClient);
      }
    };

    chatAPIClient.on('connection.changed', handleConnectionChange);

    return () => {
      chatAPIClient.off('connection.changed', handleConnectionChange);
      chatAPIClient.disconnectUser().then(() => console.log('successfully disconnected'));
    };
  }, [user]);

  if (message || !client) return <SplashScreen message={message} />;

  // TODO: should deprecate theme in Chat props
  return (
    <Chat client={client} theme={`messaging ${theme}`}>
      {children}
    </Chat>
  );
};
