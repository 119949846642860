import React from 'react';
import { createRoot } from 'react-dom/client';
import './assets/fonts/roboto-v29-latin-regular.woff2';
import './assets/fonts/roboto-v29-latin-italic.woff2';
import './assets/fonts/roboto-v29-latin-500.woff2';
import 'stream-chat-react/dist/css/index.css';
import './styles/index.scss';
import Root from './Root';
import reportWebVitals from './reportWebVitals';

const root = createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <React.StrictMode>
    <Root />
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
