import { useState, useRef, useCallback } from 'react';

import { ReactComponent as Hamburger } from '../../assets/icons/hamburger.svg';
import { DropdownMenu, DropdownItem } from '../common';
import { useOnClickOutside, useLogout, useChatServicesStore, themeSelector } from '../../hooks';

export const MainMenu = () => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownReference = useRef<HTMLDivElement | null>(null);
  const buttonReference = useRef<HTMLButtonElement | null>(null);

  const handleOutsideClick = useCallback((event: MouseEvent | TouchEvent) => {
    if (buttonReference.current?.contains(event.target as Node)) return;
    setIsOpen(false);
  }, []);

  const [theme, setTheme] = useChatServicesStore(themeSelector);

  useOnClickOutside(dropdownReference, handleOutsideClick, isOpen);

  const logout = useLogout();

  return (
    <div className='main-menu' data-testid='main-menu'>
      <button
        ref={buttonReference}
        onClick={(e) => {
          e.stopPropagation();
          setIsOpen((pv) => !pv);
        }}
        className='menu-btn'
      >
        <Hamburger />
      </button>
      <DropdownMenu ref={dropdownReference} isOpen={isOpen}>
        <DropdownItem
          data-testid='logout-btn'
          onClick={(e) => {
            e.stopPropagation();
            logout();
          }}
        >
          Log out
        </DropdownItem>
        <DropdownItem
          data-testid='theme-btn'
          onClick={(e) => {
            e.stopPropagation();
            setTheme(theme === 'light' ? 'dark' : 'light');
            setIsOpen(false);
          }}
        >
          Toggle theme
        </DropdownItem>
      </DropdownMenu>
    </div>
  );
};
